<script>
import AsahiButton from '@/components/library/AsahiButton.vue'

export default {
  name: 'AsahiBsSearchNewsAndArticles',
  components: {
    AsahiButton
  },
  props: {
    searchType: {
      type: String
    }
  },
  computed: {
    articles () {
      return this.$store.state.articles.searchArticles.results
    },
    isMobile () {
      return window.innerWidth <= 768
    }
  },
  methods: {
    navigateToNews (url) {
      this.$gtm.trackEvent({
        event: 'click_interaction',
        action: 'button_news_interaction',
        component: 'search'
      })
      this.$router.push(url)
    }
  }
}
</script>

<template v-if='articles?.length > 0'>
  <div class="row">
    <div v-for="(item, index) in articles" :key="item.id" :class="isMobile ? 'col-6': 'col-3'">
      <template v-if="index < 4">
        <div class="asahi-search-results-item">
          <div class="asahi-search-results-item-img">
            <img :src="item?.image?.link" />
          </div>
          <p>
            <span v-html="item?.title?.replace(RegExp('(' + searchValue + ')', 'gi'), '<b>$1</b>')"></span>
          </p>
        </div>
      </template>
    </div>
  </div>
  <div class="row justify-content-center asahi-tabs-container-cta">
    <div v-if='articles?.length <= 0 || articles === null' class='fw-bold d-flex justify-content-center mb-4'>{{$t('No Result Found')}}</div>
    <div class="col-auto">
      <router-link to="/news" @click.prevent="navigateToNews('/news')">
          <AsahiButton type="secondary">{{ $t('All results in this section') }}</AsahiButton>
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.asahi-search-results-item p {
  text-align: left;
}
</style>
