<script>
import AsahiButton from '@/components/library/AsahiButton.vue'

export default {
  name: 'AsahiBsSearchMarketingMaterials',
  components: {
    AsahiButton
  },
  props: {
    searchType: {
      type: String
    }
  },
  computed: {
    filesStore () {
      return this.$store.state.files
    },
    isMobile () {
      return window.innerWidth <= 768
    }
  },
  methods: {
    navigateToMaterials (url) {
      this.$gtm.trackEvent({
        event: 'click_interaction',
        action: 'button_materials_interaction',
        component: 'search'
      })
      this.$router.push(url)
    }
  }
}
</script>

<template v-if='this.filesStore.marketingMaterials.results?.length > 0'>
  <div class="row">
    <div v-for="(item, index) in this.filesStore.marketingMaterials.results" :key="item.id" :class="isMobile ? 'col-6': 'col-3'">
      <template v-if="index < 4">
        <div class="asahi-search-results-item">
          <div class="asahi-search-results-item-img" >
            <img v-if='item?.file?.link' :src="item?.file?.link" />
            <img v-else src="@/assets/icons/directory.svg" />
          </div>
          <p>
            <span v-html="item?.name?.replace(RegExp('(' + searchValue + ')', 'gi'), '<b>$1</b>')"></span>
          </p>
        </div>
      </template>
    </div>
  </div>
  <div class="row justify-content-center asahi-tabs-container-cta">
    <div v-if='this.filesStore.marketingMaterials.results?.length <= 0' class='fw-bold d-flex justify-content-center mb-4'>{{$t('No Result Found')}}</div>
    <div class="col-auto">
      <router-link to="/marketing-materials" @click.prevent="navigateToMaterials('/marketing-materials')">
          <AsahiButton type="secondary">{{ $t('All results in this section') }}</AsahiButton>
      </router-link>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.asahi-search-results-item p {
  text-align: center;
}
</style>
