<template>
  <div class='row'>
    <div v-for='(item, index) in products' :key='item.id' :class="isMobile ? 'col-6': 'col-3'">
      <template v-if='index < 4'>
        <div class='asahi-search-results-item cursor-pointer' :class="{'mt-3': isMobile}"
             @click='navigateToProductPage(item)'>
          <div class='asahi-search-results-item-img'
               v-bind:style="{ backgroundImage: 'url(' + item.small_image.url + ')' }"></div>
          <div class='d-flex flex-column gap-2'>
            <p>
              <span v-html="item.name.replace(RegExp('(' + searchType + ')', 'gi'), '<b>$1</b>')"></span>
            </p>
            <p class='d-flex gap-1' v-if='!isMobile'>{{ $t('Price per packaging incl. VAT') }}
              <b>{{ getPrice(item) }}</b></p>
            <p v-else><b>{{ getPrice(item) }}</b> {{ $t('with VAT') }}</p>
          </div>
        </div>
      </template>
    </div>
  </div>
  <div class='row justify-content-center asahi-tabs-container-cta'>
    <div v-if='products.length <= 0' class='fw-bold d-flex justify-content-center mb-4'>{{ $t('No Result Found') }}
    </div>
    <div class='col-auto' :class="{'mt-4': isMobile}">
      <router-link to='/catalog/products'>
        <AsahiButton type='secondary' @click='navigateToProducts'>{{ $t('All results in this section') }}</AsahiButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import AsahiButton from '@/components/library/AsahiButton.vue'
import formatCurrency from '@/utils/mixin'

export default {
  name: 'AsahiBsSearchProducts',
  emits: ['closeModal'],
  components: {
    AsahiButton
  },
  mixins: [formatCurrency],
  props: {
    searchType: {
      type: String
    }
  },
  computed: {
    ...mapState('catalog', ['products']),
    isMobile () {
      return window.innerWidth <= 768
    }
  },
  methods: {
    navigateToProducts () {
      localStorage.setItem('searchTypeProduct', this.searchType)
      this.$gtm.trackEvent({
        event: 'click_interaction',
        action: 'button_products_interaction',
        component: 'search'
      })
      this.$router.push('/catalog/products')
      this.$emit('closeModal')
    },
    navigateToProductPage (item) {
      this.$gtm.trackEvent({
        event: 'select_item',
        action: item.name,
        component: 'search',
        ecoomerce: {
          value: '',
          currency: 'CZK',
          items: [{
            item_id: item.sku,
            item_name: item.name,
            item_tag_last: item.product_last === 1,
            item_tag_new: item.product_new === 1,
            item_tag_recommended: item.product_recommended === 1,
            item_tag_sale: item.product_sale === 1,
            volume: item.volume,
            price: item.your_price_tax
          }]
        }
      })
      this.$navigate(`/catalog/${item.sku}`)
      this.$emit('closeModal')
    },
    getPrice (item) {
      return this.formatCurrency(item.__typename === 'BundleProduct' ? item.bundle_price_sum_tax : item.your_price_tax)
    }
  }
}
</script>

<style lang='scss' scoped>
.asahi-search-results-item p {
  text-align: left;
}
</style>
