
import { defineComponent } from 'vue'
import LoaderSpinner from '@/components/common/LoaderSpinner.vue'

export default defineComponent({
  name: 'AsahiPopup',
  components: {
    LoaderSpinner
  },
  props: {
    open: Boolean,
    isPadding: Boolean,
    options: Array
  },
  data () {
    return {
      isLoading: false
    }
  },
  computed: {
    user () {
      return this.$store.state.auth.user
    }
  },
  methods: {
    closePopup () {
      this.$emit('update:open', false)
    },
    navigateToPath (link: any) {
      this.$gtm.trackEvent({
        event: 'click_interaction',
        action: link.label,
        component: 'header'
      })

      this.onClickMenuItem(link.href || link.link || '')
    },
    onClickMenuItem (link: string) {
      const regex = /^(http|https)/
      if (link.match(regex)) {
        window.open(link, '_blank') // Open external links in a new tab
      } else {
        this.$router.push(link) // Internal navigation
      }
    },
    handleMenuClick (item: any) {
      if (item.name === 'Log Out') {
        this.$gtm.trackEvent({
          event: 'click_interaction',
          action: 'Log Out',
          component: 'header'
        })
        this.userLogout()
      } else if (
        item?.name === 'Academy' ||
        item?.name === 'Pub Menu'
      ) {
        this.$gtm.trackEvent({
          event: 'click_interaction',
          action: item?.name,
          component: 'header'
        })
        window.gigya.fidm.saml.initSSO({
          spName: this.getSPName(item?.name),
          redirectURL: item.href
        })
      }
    },
    userLogout () {
      this.isLoading = true

      const params = {
        UID: this.user.UID,
        callback: this.handleLogout
      }
      window.gigya.accounts.logout(params)
    },
    handleLogout () {
      this.$store.dispatch('logout')
      this.isLoading = false
      this.$navigate('/login')
    },
    getSPName (name: string) {
      let spName = ''
      if (name === 'Academy') {
        spName = this.user.country === 'SVK' ? 'skELearning' : 'eLearning'
      }
      if (name === 'Pub Menu') {
        spName = this.user.country === 'SVK' ? 'skPubMenu' : 'pubMenu'
      }
      return spName
    }
  }
})
