import { constants } from '@/constants'
export default {
  methods: {
    formatDate (dateString: string, options: Intl.DateTimeFormatOptions = { dateStyle: 'medium' }, lang = 'cs-CZ') {
      if (dateString !== '' && dateString != null && dateString !== undefined) {
        const date = new Date(dateString)
        return new Intl.DateTimeFormat(lang, options).format(date)
      }
    },
    formatDateAndTime (dateString: string, hideSeconds?: boolean) {
      if (dateString) {
        const date = new Date(dateString)
        return new Intl.DateTimeFormat('cs-CZ', { dateStyle: 'short', timeStyle: hideSeconds ? 'short' : 'medium' }).format(date)
      }
    },
    formatCurrency (currency: number) {
      if (localStorage.getItem('store') && localStorage.getItem('store')?.includes('SK')) {
        return new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'EUR' }).format(currency)
      }
      return new Intl.NumberFormat('cs-CZ', { style: 'currency', currency: 'CZK' }).format(currency)
    },
    formatPercentage (percentage: number) {
      return new Intl.NumberFormat('cs-CZ', { style: 'percent', maximumFractionDigits: 1 }).format(percentage / 100)
    },
    minusValue (value: number) {
      return value > 0 ? -value : value
    },

    formateDateWithoutLocale (date: string) {
      if (date && typeof date === 'string') {
        const structuredDate = new Date(date)
        if (structuredDate) {
          return `${('0' + structuredDate.getDate()).slice(-2)}.${('0' + (structuredDate?.getMonth() + 1))?.slice(-2)}.${structuredDate.getFullYear()}`
        }
      }
      return ''
    },

    formateDateAndTimeWithoutLocale (date: string) {
      if (date && typeof date === 'string') {
        const structuredDate = new Date(date)
        if (structuredDate) {
          return `${('0' + structuredDate.getDate()).slice(-2)}.${('0' + (structuredDate?.getMonth() + 1))?.slice(-2)}.${structuredDate.getFullYear()} ${('0' + structuredDate.getHours()).slice(-2)}:${('0' + structuredDate.getMinutes()).slice(-2)}`
        }
      }
      return ''
    }
  }
}
