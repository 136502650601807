
import { defineComponent, PropType } from 'vue'
import SocialBannerNew from '@/components/library/AsahiSocialBanner.vue'
import AsahiLogoNew from '@/components/library/assets/AsahiLogoNew.vue'
import { Collapse } from 'bootstrap'
import { hasPermission } from '@/utils/permissions'
import { mapGetters } from 'vuex'
import { AuthMutationTypes } from '@/store/modules/auth/interfaces/mutation-types'

interface FooterLink {
  label: string
  href?: string
  link?: string
  target?: string
  name?: string
  permission?: string
}

interface FooterSection {
  name?: string
  title?: string
  label?: string
  links?: FooterLink[]
  children?: FooterLink[]
}

interface HeaderData {
  label: string;
  children: any[];
  name: string;
}

export default defineComponent({
  name: 'AsahiFooter',
  components: { AsahiLogoNew, SocialBannerNew },
  emits: ['languagechange'],
  props: {
    headerData: {
      type: Object as PropType<HeaderData[]>,
      required: true
    },
    language: {
      type: Array as () => string[],
      required: true
    },
    activeLanguage: {
      type: String,
      required: true
    },
    footerData: {
      type: Object as () => FooterSection[],
      required: true
    },
    brandData: {
      type: Object as () => FooterLink[],
      required: true
    },
    cookies: {
      type: Object as () => FooterLink[],
      required: true
    },
    termsOfUse: {
      type: Array as () => FooterLink[],
      required: true
    },
    privacyPolicy: {
      type: Array as () => FooterLink[],
      required: true
    }
  },
  // data () {
  //   return {
  //     openLanguage: false,
  //     combinedFooterData: [] as FooterSection[]
  //   }
  // },
  data: () => ({
    openLanguage: false,
    combinedFooterData: [] as FooterSection[]
  }),
  computed: {
    ...mapGetters('main', ['getPermissions']),
    user () {
      return this.$store.state.auth.user
    },
    allPermission () {
      return this.user.allAvailablePermission
    },
    isPOSUser () {
      return this.user?.permissionSchema?.some((company) => company.rpDetails?.some((rp) => rp.customerType === 'POS' && this.user.country === 'CZE'))
    }
  },
  methods: {
    navigateToPath (link: FooterLink) {
      this.$gtm.trackEvent({
        event: 'click_interaction',
        action: link.name || link.label,
        component: 'footer'
      })

      this.$navigate(link.href || link.link || '')
    },

    closeLangAccordion () {
      const myCollapse = document.getElementById('asahi-bs-footer-accordion-collapse-language')
      if (myCollapse?.classList.contains('show')) {
        const bsCollapse = new Collapse(myCollapse, {
          toggle: true
        })
      }
    },

    openLanguageDropdown () {
      this.openLanguage = !this.openLanguage
    },
    changeLanguage (lang: string) {
      if (lang !== this.activeLanguage) {
        this.$emit('languagechange', lang)
        this.setMagentoStore(lang)
      }
    },
    getFullLanguageName (code: string): string {
      const languageMap: { [key: string]: string } = { en: 'English', cs: 'Česky', sk: 'Slovensky' }
      return languageMap[code] || code
    },
    combineFooterData () {
      const orderedData: FooterSection[] = []
      const catalogSection = this.footerData.find(section => section.name === 'products_catalogue')
      const ordersSection = this.footerData.find(section => section.name === 'Orders')
      const otherSections = this.footerData.filter(section => section.name !== 'Orders' && section.name !== 'Create an order' && section.name !== 'products_catalogue')
      const myAccountSection = this.newMenu().find(section => section.name === 'profile')

      if (catalogSection) {
        orderedData.push(catalogSection)
      }
      if (ordersSection) {
        orderedData.push(ordersSection)
      }

      if (this.brandData) {
        orderedData.push({
          title: this.$t('Our Brands'),
          links: this.brandData
        })
      }

      otherSections.forEach((section, index) => {
        if (index === 1) {
          if (myAccountSection) {
            orderedData.push(myAccountSection)
          }
        }
        orderedData.push(section)
      })

      if (myAccountSection && !orderedData.includes(myAccountSection) && orderedData.length < 4) {
        orderedData.push(myAccountSection)
      }

      this.combinedFooterData = orderedData
    },
    newMenu (): FooterSection[] {
      return [
        {
          label: this.$t('My account'),
          name: 'profile',
          children: this.getFooterChildren()
        }
      ]
    },
    getFooterChildren () {
      const footerLinks: FooterLink[] = [
        {
          name: 'My Profile',
          label: this.$t('My Profile'),
          href: '/my-profile/details',
          permission: 'My Profile'
        },
        {
          name: 'My Documents',
          label: this.$t('My Documents'),
          href: '/my-documents',
          permission: 'My Documents'
        },
        {
          name: 'Reporting',
          label: this.$t('Reporting'),
          href: '/reporting',
          permission: 'Reporting'
        },
        {
          name: 'Contracts',
          label: this.$t('Contracts'),
          href: '/contracts',
          permission: 'Contracts'
        }
      ]
      // If the user is a POSUser, filter out 'Contracts' from the footer links.
      const filteredLinks = this.isPOSUser
        ? footerLinks.filter(link => link.name !== 'Contracts')
        : footerLinks
      return filteredLinks.filter((f) => {
        return f.permission && hasPermission(this.allPermission, this.getPermissions(), f.permission)
      })
    },
    menuNavigate (link: FooterLink) {
      if (
        link?.name === 'Academy' ||
        link?.name === 'Pub Menu'
      ) {
        window.gigya.fidm.saml.initSSO({
          spName: this.getSPName(link?.name),
          redirectURL: link.href
        })
      }
    },
    getSPName (name: string) {
      let spName = ''
      if (name === 'Academy') {
        spName = this.user.country === 'SVK' ? 'skELearning' : 'eLearning'
      }
      if (name === 'Pub Menu') {
        spName = this.user.country === 'SVK' ? 'skPubMenu' : 'pubMenu'
      }
      return spName
    },
    setMagentoStore (language: string) {
      if (this.user.country === 'SVK' || (this.user.country == null && window.location.hostname.includes('.sk'))) {
        localStorage.setItem('store', `LivePortal_SK_${language}`)
      }
      if (this.user.country === 'CZE' || (this.user.country == null && window.location.hostname.includes('.cz'))) {
        localStorage.setItem('store', `LivePortal_CZ_${language}`)
      }
      if (this.user.country === 'POL' || (this.user.country == null && window.location.hostname.includes('.cz'))) {
        localStorage.setItem('store', `LivePortal_PL_${language}`)
      }

      if (!(this.user.country == null || this.user.country === '') && !this.$store.state.auth.isLanguageLoaded) {
        this.$store.commit(AuthMutationTypes.SET_LANGUAGE_LOADED, true)
      }
    }
  },
  mounted () {
    if (this.footerData) {
      this.combineFooterData()
    }
  },
  watch: {
    footerData: {
      deep: true,
      handler (newVal) {
        if (newVal && newVal.length > 0) {
          this.combineFooterData()
        }
      }
    },
    brandData: {
      deep: true,
      handler (newVal) {
        if (newVal && Object.keys(newVal).length > 0) {
          this.combineFooterData()
        }
      }
    }
  }
})
