import { apolloClient } from '@/store/graphql.config'
import { fetchCategoriesQuery } from '@/store/modules/catalog/queries/fetchCategories.query'
import { IProduct, IProductInfo } from '@/store/modules/catalog/interfaces/products/IProduct'
import { ICategory } from '@/store/modules/catalog/interfaces/categories/ICategory'
import { fetchProductsQuery } from '@/store/modules/catalog/queries/fetchProducts.query'
import { fetchProductsAggregationsQuery } from '@/store/modules/catalog/queries/fetchProductsAggregations.query'
import { fetchProductQuery } from '@/store/modules/catalog/queries/fetchProduct.query'
import { fetchWishlistQuery } from '@/store/modules/catalog/queries/fetchWishlist.query'
import { fetchRecommendedListQuery } from '@/store/modules/catalog/queries/fetchRecommendedList.query'
import { fetchProductsToWishlistMutation } from '@/store/modules/catalog/mutations/fetchProductsToWishlist.mutation'
import {
  IFetchProductsResponse,
  IFetchProductsPageInfo,
  IAggregations, ICategoryFilter
} from '@/store/modules/catalog/interfaces/products/api/fetchProducts/IFetchProductsResponse.'
import { IFetchProductsRequest } from '@/store/modules/catalog/interfaces/products/api/fetchProducts/IFetchProductsRequest'
import { IWishList } from '@/store/modules/catalog/interfaces/wishList/IWishList'
import { IRecommendItem } from '@/store/modules/catalog/interfaces/recommended/IRecommendItems'
import { IFetchWishListResponse } from '@/store/modules/catalog/interfaces/wishList/api/fetchWishList/IFetchWishListResponse'
import { IFetchRecommendItemsResponse } from '@/store/modules/catalog/interfaces/recommended/api/fetchRecommended/IFetchRecommItemsResponse'
import { IFetchRecommItemsRequest } from '@/store/modules/catalog/interfaces/recommended/api/fetchRecommended/IFetchRecommItemsRequest'
import { IFetchAddToWishListRequest } from '@/store/modules/catalog/interfaces/wishList/api/fetchAddToWishList/IFetchAddToWishListRequest'
import {
  IFetchAddToWishListResponse,
  ItemType
} from '@/store/modules/catalog/interfaces/wishList/api/fetchAddToWishList/IFetchAddToWishListResponse'
import { CatalogMutationType } from '@/store/modules/catalog/interfaces/mutation-type'
import { ActionTree, MutationTree } from 'vuex'
import { CatalogActionType } from '@/store/modules/catalog/interfaces/action-type'
import { IFetchCategoriesResponse } from '@/store/modules/catalog/interfaces/categories/fetchCategories/IFetchCategoriesResponse'
import { ICatalogState } from '@/store/modules/catalog/interfaces/ICatalogState'
import { ICatalogMutations } from '@/store/modules/catalog/interfaces/ICatalogMutations'
import { AlignmentType } from '@/store/modules/catalog/interfaces/common/aligmentType'
import { IRootState } from '@/store'
import { IError } from '@/store/modules/catalog/interfaces/common/IError'
import { IQueries } from '@/store/modules/catalog/interfaces/common/IQueries'
import { IFetchCategoriesRequest } from '@/store/modules/catalog/interfaces/categories/fetchCategories/IFetchCategoriesRequest'
import { IFilter } from '@/store/modules/catalog/interfaces/common/IFilter'
import { IFetchRemoveFromWishListRequest } from '@/store/modules/catalog/interfaces/wishList/api/fetchRemoveFromWithList/IFetchRemoveFromWithListRequest'
import { IFetchRemoveFromWishListResponse } from '@/store/modules/catalog/interfaces/wishList/api/fetchRemoveFromWithList/IFetchRemoveFromWithListResponse'
import { fetchProductFromWishlistMutation } from '@/store/modules/catalog/mutations/fetchProductFromWishlist.mutation'
import { ICatalogActions } from '@/store/modules/catalog/interfaces/ICatalogActions'
import { updateObjectInArray } from '@/utils/functions/updateObjectInArray'
import { fetchAvailablePackagingQuery } from '@/store/modules/catalog/queries/fetchAvailablePackagingQuery'
import { IFetchAvailablePackagingRequest } from '@/store/modules/catalog/interfaces/products/api/fetchAvailablePackaging/IFetchAvailablePackagingRequest'
import {
  IAvaliablePackagingItem,
  IFetchAvailablePackagingResponse
} from '@/store/modules/catalog/interfaces/products/api/fetchAvailablePackaging/IFetchAvailablePackagingResponse'
import { AxiosResponse } from 'axios'
import { generalServiceV1API } from '@/store/api-instance'
import { IFetchShipToRequest } from './interfaces/shipto/IShipToRequest'
import { IFetchShipToResponse, IShipToBySapId } from './interfaces/shipto/IShipToResponse'
import { constants } from '@/constants'
import { fetchProductWishlistQuery } from './queries/fetchProductWishlistQuery'

const defaultProductsState = {
  products: [],
  productsInfo: {
    page_size: 10,
    total_pages: 2,
    current_page: 1
  }
}

const state = (): ICatalogState => ({
  ...defaultProductsState,
  categories: [],
  filters: [],
  favorites: [],
  favoritesInfo: {} as IProductInfo,
  openedProduct: null,
  wishlist: null,
  recommendItems: null,
  recommendedList: [],
  loading: false,
  favoritesLoading: false,
  alignment: 'grid',
  queries: {
    sort: { catalog_product_name: 'ASC' },
    search: '',
    category_uid: [],
    brand: [],
    sub_brand: [],
    filter_packaging: [],
    type: [],
    material_type: [],
    price: [],
    volume: []
  },
  errors: null,
  shipTos: [],
  productWishlist: [],
  productWishlistInfo: {} as IProductInfo,
  categoryFilter: [],
  continueModalTitle: '',
  continueModalContinueMessage: '',
  continueModalNewMessage: ''
})

const mutations: MutationTree<ICatalogState> & ICatalogMutations = {
  [CatalogMutationType.SET_LOADING] (state, payload: boolean) {
    state.loading = payload
  },
  [CatalogMutationType.SET_PRODUCTS] (state, payload: IProduct[]) {
    state.products = payload
  },
  [CatalogMutationType.SET_CATEGORIES_FILTER] (state, payload: ICategoryFilter[]) {
    state.categoryFilter = payload
  },
  [CatalogMutationType.SET_CONTINUE_MODAL_TITLE] (state, payload: string) {
    state.continueModalTitle = payload
  },

  [CatalogMutationType.SET_CONTINUE_MODAL_CONTINUE_MESSAGE] (state, payload: string) {
    state.continueModalContinueMessage = payload
  },

  [CatalogMutationType.SET_CONTINUE_MODAL_NEW_MESSAGE] (state, payload: string) {
    state.continueModalNewMessage = payload
  },
  [CatalogMutationType.SET_PRODUCTS_INFO] (state, payload: IFetchProductsPageInfo) {
    state.productsInfo = payload
  },
  [CatalogMutationType.RESET_PRODUCTS_PAGE] (state) {
    state.products = [...defaultProductsState.products]
    state.productsInfo = { ...defaultProductsState.productsInfo }
  },
  [CatalogMutationType.SET_FILTERS] (state, payload: IAggregations[]) {
    state.filters = payload
  },
  [CatalogMutationType.SET_FAVORITES] (state, payload: IProduct[]) {
    state.favorites = payload
  },
  [CatalogMutationType.SET_FAVORITES_INFO] (state, payload: IFetchProductsPageInfo) {
    state.favoritesInfo = payload
  },
  [CatalogMutationType.SET_PRODUCT] (state, payload: IProduct) {
    state.openedProduct = payload
  },
  [CatalogMutationType.SET_PACKAGING] (state, payload: IAvaliablePackagingItem[]) {
    if (!state.openedProduct) return
    state.openedProduct.avaliablePackaging = payload
  },
  [CatalogMutationType.SET_WISHLIST] (state, payload: IWishList) {
    state.wishlist = payload
  },
  [CatalogMutationType.SET_RECOMMENDED] (state, payload: IProduct[]) {
    state.recommendedList = payload
  },
  [CatalogMutationType.SET_CATEGORIES] (state, payload: ICategory[]) {
    state.categories = payload
  },
  [CatalogMutationType.SET_ALIGNMENT] (state, payload: AlignmentType) {
    state.alignment = payload
  },
  [CatalogMutationType.SET_QUERIES] (state, payload: IQueries) {
    state.queries = payload
  },
  [CatalogMutationType.SET_ERROR] (state, payload: IError[]) {
    state.errors = payload
  },
  [CatalogMutationType.SET_FAVORITES_LOADING] (state, payload: boolean) {
    state.favoritesLoading = payload
  },
  [CatalogMutationType.SET_SHIP_TO] (state, payload: IShipToBySapId[]) {
    state.shipTos = payload
  },
  [CatalogMutationType.SET_PRODUCT_WISHLIST] (state, payload: IProduct[]) {
    state.productWishlist = payload
  },
  [CatalogMutationType.SET_PRODUCT_WISHLIST_INFO] (state, payload: IProductInfo) {
    state.productWishlistInfo = payload
  }
}
export const actions: ActionTree<ICatalogState, IRootState> & ICatalogActions = {
  async [CatalogActionType.FETCH_PRODUCTS] ({ commit, state }, dto: IFetchProductsRequest & IFilter) {
    try {
      const variables = Object.entries({
        is_peroni: constants.application.theme === 'peroni',
        currentPage: 1,
        ...state.queries,
        ...dto
      }).reduce((acc: { [key: string]: any }, [key, value]) => {
        if (Array.isArray(value)) {
          if (value.length > 0) acc[key] = value
        } else if (value != null && value !== '') acc[key] = value
        return acc
      }, {})

      if (!dto?.bypassloading) {
        commit(CatalogMutationType.SET_LOADING, true)
      }

      const { data } = await apolloClient.query<IFetchProductsResponse, IFetchProductsRequest & IFilter>({
        query: fetchProductsQuery,
        variables
      })
      if (data) {
        commit(CatalogMutationType.SET_PRODUCTS, data?.products?.items || [])
        commit(CatalogMutationType.SET_PRODUCTS_INFO, {
          ...(data?.products?.page_info || {}),
          total_count: data?.products?.total_count || 0
        })
        commit(CatalogMutationType.SET_FILTERS, data?.products?.aggregations || [])
        commit(CatalogMutationType.SET_CATEGORIES_FILTER, Array.isArray(data?.products?.categoryFilter) ? data?.products?.categoryFilter : [])
        commit(CatalogMutationType.SET_LOADING, false)
      }
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      commit(CatalogMutationType.SET_LOADING, false)
      throw e
    }
  },
  async [CatalogActionType.FETCH_PRODUCTS_AGGREGATIONS] ({ commit }) {
    try {
      commit(CatalogMutationType.SET_LOADING, true)
      const { data } = await apolloClient.query<IFetchProductsResponse, IFetchProductsRequest & IFilter>({
        query: fetchProductsAggregationsQuery,
        variables: {
          // sku: state.queries.sku,
        }
      })
      commit(CatalogMutationType.SET_FILTERS, data?.products?.aggregations || [])
      commit(CatalogMutationType.SET_LOADING, false)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      commit(CatalogMutationType.SET_LOADING, false)
      throw e
    }
  },
  async [CatalogActionType.FETCH_PRODUCT] ({ commit, state }, dto: IFetchAvailablePackagingRequest) {
    try {
      commit(CatalogMutationType.SET_LOADING, true)
      const { data } = await apolloClient.query<IFetchProductsResponse, IFetchAvailablePackagingRequest>({
        query: fetchProductQuery,
        variables: {
          sku: dto?.sku
        }
      })
      if (data?.products?.items?.length > 1) {
        const product = data?.products?.items?.filter((product: IProduct) => product.sku === dto?.sku) as any
        commit(CatalogMutationType.SET_PRODUCT, { ...product[0], type: '' })
      } else {
        commit(CatalogMutationType.SET_PRODUCT, { ...data.products.items[0], type: '' })
      }

      const { data: packaging } = await apolloClient.query<
        IFetchAvailablePackagingResponse,
        IFetchAvailablePackagingRequest
      >({
        query: fetchAvailablePackagingQuery,
        variables: {
          sku: '',
          brand_code: state.openedProduct?.sap_sub_brand_code
        }
      })
      commit(CatalogMutationType.SET_PACKAGING, packaging.getProductAvaliablePackaging)

      commit(CatalogMutationType.SET_LOADING, false)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      commit(CatalogMutationType.SET_LOADING, false)
      throw e
    }
  },
  async [CatalogActionType.FETCH_WISHLIST] ({ commit }, dto: IFetchProductsRequest) {
    if (!dto?.bypassloading) {
      commit(CatalogMutationType.SET_LOADING, true)
    }
    try {
      const getWishListData = async (
        currentPage = 1,
        oldData: IFetchWishListResponse = { customer: { wishlists: [] } }
      ): Promise<IFetchWishListResponse> => {
        const { data: wishListData } = await apolloClient.query<IFetchWishListResponse, object>({
          query: fetchWishlistQuery,
          variables: { currentPage }
        })

        oldData.customer.wishlists[0] = {
          ...(oldData.customer.wishlists?.[0] || {}),
          ...wishListData.customer.wishlists[0],
          items_v2: {
            ...(oldData.customer.wishlists?.[0]?.items_v2 || {}),
            ...wishListData.customer.wishlists[0].items_v2,
            items: [
              ...(oldData.customer.wishlists?.[0]?.items_v2?.items || []),
              ...wishListData.customer.wishlists[0].items_v2.items
            ]
          }
        }

        if (
          wishListData.customer.wishlists[0].items_v2.shipto_item_count >
          oldData.customer.wishlists[0].items_v2.items.length
        ) {
          return await getWishListData(currentPage + 1, oldData)
        }

        return oldData
      }

      const wishListData = await getWishListData()
      commit(CatalogMutationType.SET_WISHLIST, wishListData.customer.wishlists[0])
      commit(CatalogMutationType.SET_LOADING, false)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      commit(CatalogMutationType.SET_LOADING, false)
      throw e
    }
    commit(CatalogMutationType.SET_LOADING, false)
  },
  async [CatalogActionType.FETCH_FAVORITES] ({ dispatch, commit, state }, dto: IFetchProductsRequest) {
    try {
      if (!dto?.bypassloading) {
        commit(CatalogMutationType.SET_LOADING, true)
      }

      if (!dto.currentPage) {
        await dispatch(CatalogActionType.FETCH_WISHLIST)
      }

      const skus: string[] =
        state.wishlist?.items_v2.items
          ?.map((item: ItemType) => item.product.sku)
          ?.filter((sku: string) => sku != null) || []

      const filterData = Object.entries({
        is_peroni: constants.application.theme === 'peroni',
        currentPage: 1,
        ...state.queries,
        ...dto
      }).reduce((acc: { [key: string]: any }, [key, value]) => {
        if (Array.isArray(value)) {
          if (value.length > 0) acc[key] = value
        } else if (value != null && value !== '') acc[key] = value
        return acc
      }, {})

      const { data } = await apolloClient.query<IFetchProductsResponse, IFetchProductsRequest>({
        query: fetchProductsQuery,
        variables: {
          sku: skus,
          currentPage: 1,
          ...filterData
        }
      })

      commit(CatalogMutationType.SET_FAVORITES, data.products.items)
      commit(CatalogMutationType.SET_FILTERS, data?.products?.aggregations || [])
      commit(CatalogMutationType.SET_FAVORITES_INFO, {
        ...(data?.products?.page_info || {}),
        total_count: data?.products?.total_count || 0
      })
      commit(CatalogMutationType.SET_LOADING, false)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      commit(CatalogMutationType.SET_LOADING, false)
      throw e
    }
  },
  async [CatalogActionType.FETCH_RECOMMENDED_PRODUCTS] ({ dispatch, commit, state }, dto: IFetchRecommItemsRequest) {
    try {
      commit(CatalogMutationType.SET_LOADING, true)

      const { data: recommendItems } = await apolloClient.query<IFetchRecommendItemsResponse, object>({
        query: fetchRecommendedListQuery,
        variables: { ...dto }
      })

      const recItems: IRecommendItem[] =
        recommendItems.recommendItems?.recItems
          ?.sort((a, b) => {
            const p1 = parseInt(a.Priority)
            const p2 = parseInt(b.Priority)
            return p1 !== p2 ? (p1 > p2 ? 1 : -1) : 0
          }).map((item: any) => {
            const promo_price_leaflet = {
              promo_price: item.promo_price,
              long_term_discount: item.long_term_discount,
              promo_price_percentage_with_tax: item.promo_price_percentage_with_tax,
              promo_price_percentage_wo_tax: item.promo_price_percentage_wo_tax,
              long_term_discount_percentage_with_tax: item.long_term_discount_percentage_with_tax,
              long_term_discount_percentage_wo_tax: item.long_term_discount_percentage_wo_tax
            }
            return {
              ...item,
              promo_price_leaflet
            }
          })

      commit(CatalogMutationType.SET_RECOMMENDED, recItems)
      commit(CatalogMutationType.SET_LOADING, false)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      commit(CatalogMutationType.SET_LOADING, false)
      throw e
    }
  },
  async [CatalogActionType.FETCH_ADD_TO_WISHLIST] ({ commit, state }, dto: IFetchAddToWishListRequest) {
    try {
      commit(CatalogMutationType.SET_LOADING, true)
      commit(CatalogMutationType.SET_FAVORITES_LOADING, true)
      const { data: wishList } = await apolloClient.mutate<IFetchAddToWishListResponse, IFetchAddToWishListRequest>({
        mutation: fetchProductsToWishlistMutation,
        refetchQueries: [{ query: fetchWishlistQuery }],
        variables: {
          wishlist_id: dto.wishlist_id,
          sku: dto.sku,
          currentPage: 1
        }
      })
      commit(CatalogMutationType.SET_WISHLIST, wishList?.addProductsToWishlist.wishlist as IWishList)
      const data = updateObjectInArray(state.products, dto.sku, 'sku', { favourites: 1 })
      commit(CatalogMutationType.SET_PRODUCTS, data)
      const skus: string[] = []
      const items = wishList?.addProductsToWishlist.wishlist.items_v2.items
      items?.map((item: ItemType) => skus.push(item.product.sku))

      const { data: favorites } = await apolloClient.query<IFetchProductsResponse, IFetchProductsRequest>({
        query: fetchProductsQuery,
        variables: {
          sku: skus,
          currentPage: 1
        }
      })
      commit(CatalogMutationType.SET_PRODUCT, { ...state.openedProduct, favourites: 1 } as IProduct)

      commit(CatalogMutationType.SET_FAVORITES, favorites.products.items)
      commit(CatalogMutationType.SET_FAVORITES_INFO, {
        ...(favorites?.products?.page_info || {}),
        total_count: favorites?.products?.total_count || 0
      })
      commit(CatalogMutationType.SET_FAVORITES_LOADING, false)
      commit(CatalogMutationType.SET_LOADING, false)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      commit(CatalogMutationType.SET_FAVORITES_LOADING, false)
      throw e
    }
  },
  async [CatalogActionType.FETCH_REMOVE_FROM_WISHLIST] ({ commit, state }, dto: IFetchRemoveFromWishListRequest) {
    try {
      commit(CatalogMutationType.SET_FAVORITES_LOADING, true)
      const { data: wishListData } = await apolloClient.mutate<
        IFetchRemoveFromWishListResponse,
        Omit<IFetchRemoveFromWishListRequest, 'sku'>
      >({
        mutation: fetchProductFromWishlistMutation,
        refetchQueries: [{ query: fetchWishlistQuery }],
        variables: {
          wishlist_id: dto.wishlist_id,
          wishlist_item_id: dto.wishlist_item_id,
          pageSize: dto.pageSize
        }
      })
      commit(CatalogMutationType.SET_WISHLIST, wishListData?.removeProductsFromWishlist.wishlist as IWishList)
      const data = updateObjectInArray(state.products, dto.sku, 'sku', { favourites: 0 })
      commit(CatalogMutationType.SET_PRODUCTS, data)

      commit(CatalogMutationType.SET_PRODUCT, { ...state.openedProduct, favourites: 0 } as IProduct)

      const skus: string[] = []
      const items = wishListData?.removeProductsFromWishlist.wishlist.items_v2.items
      items?.map((item: ItemType) => skus.push(item.product.sku))

      const { data: favorites } = await apolloClient.query<IFetchProductsResponse, IFetchProductsRequest>({
        query: fetchProductsQuery,
        variables: {
          sku: skus,
          currentPage: 1,
          pageSize: dto.pageSize
        }
      })
      commit(CatalogMutationType.SET_FAVORITES, favorites.products.items)
      commit(CatalogMutationType.SET_FAVORITES_INFO, {
        ...(favorites?.products?.page_info || {}),
        total_count: favorites?.products?.total_count || 0
      })

      commit(CatalogMutationType.SET_FAVORITES_LOADING, false)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      commit(CatalogMutationType.SET_FAVORITES_LOADING, false)
    }
  },
  async [CatalogActionType.FETCH_CATEGORIES] ({ commit }, dto: IFetchCategoriesRequest) {
    try {
      const { data } = await apolloClient.query<IFetchCategoriesResponse, IFetchCategoriesRequest>({
        query: fetchCategoriesQuery,
        variables: {
          url_key: dto.url_key
        }
      })
      commit(CatalogMutationType.SET_CATEGORIES, data.categoryList)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])

      throw e
    }
  },
  async [CatalogActionType.FETCH_SHIP_TO] ({ commit }, dto: IFetchShipToRequest) {
    localStorage.setItem('shipToId', dto.ship_to_id)

    try {
      const data = await fetchShipTos({
        ship_to_id: dto.ship_to_id
      })
      if (data) {
        commit(CatalogMutationType.SET_SHIP_TO, data)
        // localStorage.setItem('shipToId', dto.ship_to_id)
      }
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      throw e
    }
  },
  async [CatalogActionType.FETCH_PRODUCT_WISHLIST] ({ commit }) {
    try {
      const { data: productWishlist } = await apolloClient.query<IFetchProductsResponse, object>({
        query: fetchProductWishlistQuery,
        variables: {},
        context: {
          headers: {
            myfavourites: 1
          }
        }
      })

      commit(CatalogMutationType.SET_PRODUCT_WISHLIST, productWishlist?.products?.items || [])
      if (productWishlist) {
        commit(CatalogMutationType.SET_PRODUCT_WISHLIST_INFO, {
          ...(productWishlist?.products?.page_info || {}),
          total_count: productWishlist?.products?.total_count || 0
        })
      }
      commit(CatalogMutationType.SET_LOADING, false)
    } catch (e: unknown) {
      commit(CatalogMutationType.SET_ERROR, e as IError[])
      throw e
    }
  }
}

const fetchShipTos = async (dto: IFetchShipToRequest): Promise<IFetchShipToResponse> => {
  const response: AxiosResponse = await generalServiceV1API.get<IFetchShipToResponse>(
    `shiptos/GetShipToBySapId/${dto.ship_to_id}`
  )
  return response.data
}

const CatalogModule = {
  namespaced: true,
  state,
  mutations,
  actions
}
export default CatalogModule
