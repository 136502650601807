<template>
  <button
    class="asahi-button"
    :class="getClassObject"
    :disabled="disabled"
    v-bind="$attrs" >
    <template v-if="type === 'success'">
      <img :src='tick' :class="{'rotate': rotateIcon}" alt="Icon"/>
    </template>
    <slot></slot>
  </button>
</template>

<script>
import { defineComponent } from 'vue'
import tick from '@/assets/icons/library/tick.svg'
export default defineComponent({
  inheritAttrs: false,
  name: 'AsahiButton',

  props: {
    type: {
      type: String,
      default: 'primary'
    },
    rounded: {
      type: Boolean,
      default: true
    },
    disabled: {
      type: Boolean,
      default: false
    },
    rigid: {
      type: Boolean,
      default: false
    },
    rotateIcon: {
      type: Boolean,
      default: false
    },
    setHeight: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      tick
    }
  },
  computed: {
    getClassObject () {
      return {

        primary: this.type === 'primary',
        secondary: this.type === 'secondary',
        tertiary: this.type === 'tertiary',
        success: this.type === 'success',
        plain: this.type === 'plain',
        white: this.type === 'white',
        resetFilter: this.type === 'resetFilter',
        favorite: this.type === 'secondary-favorite',
        circle: this.type === 'circle',
        link: this.type === 'link',
        bicon: this.type === 'icon',
        padding: this.type === 'padding',
        fixed: this.type === 'fixed',
        counter: this.type === 'counter',
        roundedcorner: this.rounded,
        disabled: this.disabled,

        setHeight: this.setHeight,
        square: this.rigid
      }
    }
  }
})
</script>

<style scoped lang='scss'>
button:focus {
  outline: none;
}

.setHeight {
  height: 42px;
}

.asahi-button {
  display: flex;
  align-items: center;
  justify-content: center;
  white-space: nowrap;
  border: none;
  color: white;
  padding: 10px 16px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  &.square {
    border-radius: 0;
  }
  &.secondary-border-none {
    background-color: transparent;
    border: none;
    color: $button-secondary-text-color;
    margin: 0 2px;

  }
}
.primary {
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
}
.primary:hover {
  background-color: $button-primary-hover-color;
  color: $button-primary-text-color;
}
.primary:focus{
  border: none;
}

.secondary {
  background-color: $button-secondary-bg-color;
  color: $button-secondary-text-color;
  border: 2px solid $button-secondary-border-color;
}

.secondary:hover {
  background-color: $button-secondary-bg-color;
  color: $button-secondary-text-color;
}

.tertiary {
  background-color: $button-tertiary-bg-color;
  color: $button-tertiary-text-color;
}

.tertiary:hover {
  background-color: $button-tertiary-bg-color;
  color: $button-tertiary-text-color;
}

.success {
  background-color : $button-success-bg-color;
  color: $button-success-color;
  border: none;
}

.plain {
  background-color: $button-plain-bg-color;
  color: $button-plain-text-color;
  border: 2px solid $header-secondary-gray;
}

.white {
  background-color: $button-white-bg-color;
  color: $button-white-text-color;
  border: 2px solid $button-white-border-color;
}

.resetFilter {
  background-color: transparent;
  color: $primary-gold;
  text-decoration: underline;
  border: none;
}

.resetFilter:hover {
  background-color: transparent;
  color: $primary-gold;
  text-decoration: underline;
  border: none;
}

.favorite {
  background-color: $button-favorite-bg-color;
  color: $button-favorite-text-color;
  border: 2px solid $button-favorite-border-color;
}

.circle {
  width: 40px;
  height: 40px;
  background-color: $button-favorite-bg-color;
  color: $button-favorite-text-color;
  border: 2px solid $button-secondary-text-color;
  display: flex;
  align-items: center;
  border-radius: 100%
}

.link {
  background-color: $button-link-bg-color;
  color: $button-link-text-color;
  text-decoration: underline;
}

.bicon {
  background-color: $button-link-bg-color;
  color: $button-link-text-color;
  text-decoration: underline;
  margin: 0;
}
.padding {
  background-color: $button-link-bg-color;
  color: $button-link-text-color;
  text-decoration: underline;
  margin: 0;
  padding: 0;
}

.fixed {
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
  display: flex;
  width: 100px;
  justify-content: center;
}

.roundedcorner {
  border-radius:25px;
}

.disabled {
  cursor: not-allowed;
  background-color: $button-disabled-bg-color;
  color: $button-disabled-text-color;
  border: 2px solid $button-disabled-bg-color;
}
.disabled {
  cursor: not-allowed;
  background-color: $button-disabled-bg-color;
  color: $button-disabled-text-color;
}
.disabled:hover{
  background-color: transparent;
  color: $button-disabled-text-color;
}

.rotate {
  transition: transform 0.3s ease;
  transform: rotate(180deg);
}
.counter {
  :hover {
    background-color: transparent;
  }
}

.no-hover.primary.disabled:hover{
    background-color: $button-disabled-bg-color;
    color: $button-disabled-text-color;
}
.primary {
  background-color: $button-primary-bg-color;
  color: $button-primary-text-color;
}

.primary:hover {
  background-color: $button-primary-hover-color;
  color: $button-primary-text-color;
}

.primary.disabled, .primary.disabled:hover {
  background-color: $button-disabled-bg-color;
  color: $button-disabled-text-color;
  cursor: not-allowed;
}
</style>
